<template>
  <ImageBox
    :visible.sync="show"
    class="big-image-preview"
    :style="{ backgroundColor: 'rgba(0, 0, 0, 1)' }"
    @close-from-mask="show = false"
  >
    <div class="content">
      <div
        class="header" 
        @click="close"
      >
        <div class="btn-close">
          <Icon
            name="sui_icon_nav_close_24px"
            size="24px"
            style="height:24px"
            color="#222222"
          />
        </div>
        <div class="top-pagination">
          {{ currentIndex + 1 }}/{{ imageUrls.length }}
        </div>
        <div class="place-holder"></div>
      </div>
      <div class="image-swiper">
        <swiper-container
          ref="bigImgSwiper"
          class="big-img-swiper"
          init="false"
          :dir="GB_cssRight ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(url, index) in imageUrls"
            :key="index + url"
          >
            <div class="big-img-container">
              <img
                class="lazyload big-image"
                :src="LAZY_IMG"
                :data-src="url"
                alt="shein picture"
              />
            </div>
          </swiper-slide>
        </swiper-container>
        <span class="type-text">{{ scenesText }}</span>
      </div>
      <div class="small-swiper">
        <swiper-container
          ref="previewImgSwiper"
          class="preview-img-swiper"
          init="false"
          :dir="GB_cssRight ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(url, index) in imageUrls"
            :key="index + url"
            class="preview-img-container"
          >
            <img
              class="lazyload preview-image"
              :src="LAZY_IMG"
              :data-src="dprInsert({ img: url })"
              alt="shein picture"
            />
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </ImageBox>
</template>

<script>
import { ImageBox } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { Controller } from 'swiper/modules'
import { dprInsert } from '../utils/tools.js'

const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'ImagePreviewDialog',
  components: {
    ImageBox,
    Icon
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      GB_cssRight,
      showPreview: false,
      effects: [],
      images: [],
      bigImgSwiper: null,
      previewImgSwiper: null,
      LAZY_IMG: LAZY_IMG,
    }
  },
  computed: {
    show: {
      get() {
        return this.showPreview
      },
      set(val){
        if(!val) this.$emit('closePreview', val)
        this.showPreview = val
        if(!this.showPreview) this.$off()
      }
    },
    imageUrls() {
      return [...this.effects, ...this.images]
    },
    scenesText() {
      if(this.currentIndex >= this.effects.length) {
        return this.language.SHEIN_KEY_PWA_26442
      } else return this.language.SHEIN_KEY_PWA_26441
    },
    currentIndex() {
      return this.bigImgSwiper?.activeIndex || 0
    }
  },
  methods: {
    init(ctx) {
      const { previewStatus, effects, images } = ctx

      this.effects.splice(0, this.effects.length, ...(effects || [])) 
      this.images.splice(0, this.images.length, ...(images || []))

      let { type, index } = previewStatus || {}
      let initialSlide = 0
      if(type == 'images' && typeof index == 'number') {
        initialSlide = index + this.effects.length
      } else initialSlide = index || 0
      this.$nextTick(() => {
        this.initSwiper(initialSlide)
      })
    },
    initSwiper(initialSlide) {
      if (this.previewImgSwiper && this.bigImgSwiper) {
        this.previewImgSwiper.slideTo(initialSlide, 0, null)
        this.bigImgSwiper.slideTo(initialSlide, 0, null)
      } else {
        Object.assign(this.$refs.previewImgSwiper, {
          modules: [Controller],
          initialSlide,
          slidesPerView: 'auto',
          spaceBetween: 6,
          centeredSlides: true,
          slideToClickedSlide: true,
        })
        this.$refs.previewImgSwiper.initialize()
        this.previewImgSwiper = this.$refs.previewImgSwiper.swiper

        Object.assign(this.$refs.bigImgSwiper, {
          modules: [Controller],
          initialSlide,
          slidesPerView: 1,
          thumbs: {
            swiper: this.previewImgSwiper
          }
        })
        this.$refs.bigImgSwiper.initialize()
        this.bigImgSwiper = this.$refs.bigImgSwiper.swiper

        this.previewImgSwiper.controller.control = this.bigImgSwiper
        this.bigImgSwiper.controller.control = this.previewImgSwiper
      }
    },
    open(ctx) {
      this.init(ctx)
      this.show = true
    },
    close() {
      this.show = false
    },
    dprInsert,
  }
}
</script>

<style scoped lang="less">
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .image-swiper {
    flex-grow: 1;
    flex-shrink: 1;
    height: 0;
  }
}
.header {
  margin: 12/75rem 16/75rem;
  display: flex;
  justify-content: space-between;
  .btn-close {
    width: 64/75rem;
    height: 64/75rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top-pagination {
    font-size: 34/75rem;
    color: #ffffff;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .place-holder {
    width: 64/75rem;
  }
}
.image-swiper {
  margin: 40/75rem 0 56/75rem 0;
  position: relative;
  .big-img-container {
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }
  .big-img {
    object-fit: contain;
  }
  .big-img-swiper {
    height: 100%;
  }
  .type-text {
    position: absolute;
    bottom: 48/75rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 4/75rem 22/75rem;
    border-radius: 40/75rem;
    background: #666;
    color: #fff;
    font-size: 14px;
  }
}
.small-swiper {
  // margin-left: 18/75rem;
  margin-bottom: 48/75rem;
  .preview-img-container {
    display: flex;
    align-items: center;
    width: 142/75rem;
    height: 142/75rem;
    padding: 8/75rem;
    border: 2/75rem solid #000;
    overflow: hidden;
  }
  .preview-image {
    width: 100%;
    display: block;
  }
  .swiper-slide-active {
    border: 2/75rem solid #fff;
  }
}
</style>
