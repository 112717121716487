<template>
  <div>
    <div class="rmvip-price__container">
      <span>{{ price }}</span>
      <span class="rmvip-price__icon"></span>
    </div>
    <div class="rmvip-price__retail"> 
      {{ retailPrice && retailPrice.amountWithSymbol }} 
    </div>
  </div>
</template>

<script>
export default {
  name: 'VipPrice',
  props: {
    price: {
      type: [Number, String],
      default: 0
    },
    retailPrice: {
      type: Object,
      default: () => ({}),
    }
  }
}
</script>

<style lang="less" scoped>
@rw_exclusive_price: "/pwa_dist/rw_images/user/romwevip/vip_price-1b148c1785.png";

.rmvip-price {
  &__container {
    display: inline-block;
    height: 0.4rem;
    .font-dpr(24px);
    font-weight: bold;
    color: #936D0C;
    line-height: 0.4667rem;
    background-color: #FFE995;
    border-radius: 0.1867rem;
    padding-left: 0.2133rem;
    >span {
      vertical-align: top;
    }
  }
  &__icon {
    display: inline-block;
    height: 100%;
    width: 0.8267rem;
    background-color: #333;
    margin-left: 0.12rem;
    border-radius: 0 0.1867rem 0.1867rem 0;
    background-size: cover;
    background-image: url(@rw_exclusive_price);
  }
}
.rmvip-price__retail {
  display: inline-block;
  margin-left: 0.05rem;
  .font-dpr(24px);
  color: #767676;
  font-weight: 400;
  line-height: .4667rem;
  text-decoration: line-through;
}
</style>
