<template>
  <div
    v-if="promotionTag.typeIdText"
    class="voucher_tag"
  >
    <div
      v-tap="{
        id: '1-8-3-57',
        data: {
          goods_id: item.product.goods_id
        }
      }"
      class="voucher-tag-content"
      @click="handleShowPopover"
    >
      {{ promotionTag.typeIdText }}
      <SPopover
        v-model="showPopover"
        show-close-icon
        trigger-type="user"
        :append-to-body="appendToBody"
        :prop-style="popoverStyle"
      >
        <sui_icon_info_12px_2
          slot="reference"
          size="12px" 
        />
        <div class="voucher-popover-content">
          {{ language.SHEIN_KEY_PWA_31148 }}
        </div>
      </SPopover>
    </div>

    <template v-if="promotionTag.subText">
      <div class="tag-split-line">
        |
      </div>
      <div class="voucher-subtext">
        {{ promotionTag.subText }}
      </div>
    </template>
  </div>
</template>
<script setup>
import { defineProps, defineExpose, computed, ref } from 'vue'
import { sui_icon_info_12px_2 } from '@shein-aidc/icon-vue2'
import { Popover as SPopover } from '@shein/sui-mobile'

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {}
    }
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  },
  disableClick: {
    type: Boolean,
    default: false
  },
  page: {
    type: String,
    default: '',
  }
})
const showPopover = ref(false)

const appendToBody = computed(() => {
  return props.page != 'checkout'
})

const popoverStyle = computed(() => {
  return props.page == 'checkout' ? {
    maxWidth: '240px',
    width: 'max-content',
    fontWeight: 400,
  } : {
    maxWidth: '240px',
  }
})

const promotionTag = computed(() => {
  return props.item.multiPromotionTags?.find(tag => tag?.typeId == 33) || {}
})
function handleShowPopover() {
  if(props.disableClick) return
  showPopover.value = !showPopover.value
}
defineExpose({
  promotionTag
})
</script>
<style lang="less" scoped>
.voucher_tag {
  display: flex;
  align-items: center;
  gap: 8/75rem;
  max-width: 100%;
  background-color: #ffe6e6;
  border-radius: 4/75rem;
  font-size: 10px;
  color: #ff3f3f;
  padding: 4/75rem 8/75rem;
  flex-wrap: nowrap;
  max-width: 100%;
  width: max-content;
  .voucher-subtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.voucher-tag-content {
  display: flex;
  align-items: center;
  gap: 4/75rem;
  font-weight: 600;
}
.tag-split-line {
  opacity: 0.4;
  color: @sui_color_guide;
}
</style>

<style lang="less">
.voucher-popover-content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: normal;
}
</style>
