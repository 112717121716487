<script setup>
import { computed } from 'vue'
import { Label as SLabel } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'

const props = defineProps({
  cartQsTagInfo: {
    type: Object,
    default: () => ({})
  },
  abtInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  page: {
    type: String,
    default: ''
  }
})

const quickShipTag = computed(() => {
  if (props.page == 'checkout') {
    const { SHEIN_KEY_PWA_30733, SHEIN_KEY_PWA_30819 } = props.language
    const { OrderQuickshipStyle = {}, TradeQuickshipWord = {} } = props.abtInfo
    const tradeQuickshipWordStatus = TradeQuickshipWord?.param?.TradeQuickshipWordStatus
    const orderQuickshipStyleStatus = OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus
    return {
      text: +(tradeQuickshipWordStatus || 0)
        ? template(tradeQuickshipWordStatus, SHEIN_KEY_PWA_30733)
        : SHEIN_KEY_PWA_30819,
      isShowNewStyle: +(orderQuickshipStyleStatus || 0) ? true : false,
    }
  }

  return props.cartQsTagInfo
})

const handleTip = (text) => {
  if (!text) return ''
  return text?.split(' ')?.map(word => `<span>${word}</span>`)?.join(' ')
}

</script>

<template>
  <s-label
    type="success"
    :class="[
      'quick-ship-tag',
      {
        'quick-ship-tag_new': quickShipTag.isShowNewStyle
      }
    ]"
  >
    <span class="quick-ship-tag__title">
      <Icon
        v-if="quickShipTag.isShowNewStyle"
        name="sui_icon_qucikship_flat_24px"
        size="12px"
        class="quick-ship-tag__icon"
      />
      <span
        :class="[
          'quick-ship-tag__text',
          {
            'quick-ship-tag__text_new': quickShipTag.isShowNewStyle,
          }
        ]"
      >{{ quickShipTag.text }}</span>
    </span>
    <template v-if="quickShipTag.isShowNewStyle && !quickShipTag?.qsTimelinessInfo?.isLocationTop && quickShipTag?.qsTimelinessInfo?.tip">
      <span class="divider"></span>
      <span class="quick-ship-tag__tip">
        <span
          class="quick-ship-tip__text"
          v-html="handleTip(quickShipTag?.qsTimelinessInfo?.tip)"
        >
        </span>
      </span>
    </template>
  </s-label>
</template>

<style lang="less" scoped>
.quick-ship-tag {
  display: inline-flex!important; /* stylelint-disable-line declaration-no-important */
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
  &.quick-ship-tag_new {
    background-color: #ECFCF3;
    box-shadow: 0 0 0 0.5px rgba(25, 128, 85, 0.20) inset;
  }
  .quick-ship-tag__title {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
    .quick-ship-tag__icon {
      margin: 0 4/75rem 0 0;
    }
    .quick-ship-tag__text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .font-dpr(20px);
    }
    .quick-ship-tag__text_new {
      padding: 0 1px 0 0;
      font-style: italic;
      font-weight: 590;
    }
  }
  .divider {
    flex-shrink: 0;
    display: inline-block;
    margin: 0 8/75rem;
    width: 2/75rem;
    height: 18/75rem;
    background: rgba(25, 128, 85, .5);
  }
  .quick-ship-tag__tip {
    color: #198055;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .font-dpr(20px);
    direction: rtl;
    .quick-ship-tip__text {
      direction: ltr;
      unicode-bidi: bidi-override;
    }
  }
}
</style>
