import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-33' })


export default {
  expose_custom_content(options = {}, extraData = {}) {
    daEventCenter.triggerNotice({
      daId: '2-33-1',
      extraData: {
        ...options,
        ...extraData
      }
    })
  },
  click_custom_content_close(options = {}, extraData = {}) {
    daEventCenter.triggerNotice({
      daId: '2-33-2',
      extraData: {
        ...options,
        ...extraData
      }
    })
  },
  click_custom_content_imagedetail(options = {}, extraData = {}) {
    daEventCenter.triggerNotice({
      daId: '2-33-3',
      extraData: {
        ...options,
        ...extraData
      }
    })
  },
}
