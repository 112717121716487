import Vue from 'vue'
import { Drawer, Loading, Button, ImageBox } from '@shein/sui-mobile'
import container from './container.vue'
import ImagePreviewDialog from './components/ImagePreviewDialog.vue'
import { getLanguage } from './utils/fetcher.js'
Vue.component(Drawer.name, Drawer)
Vue.component(Loading.name, Loading)
Vue.component(Button.name, Button)
Vue.component(ImageBox.name, ImageBox)
const customDetailDialogCreator = Vue.extend(container)
const ImagePreviewDialogCreator = Vue.extend(ImagePreviewDialog)


class CustomDetailDialog {
  constructor(el = null) {
    if(!CustomDetailDialog.instance) {
      this.instance = null
      this.language = null
      this.previewInstance = null
      if(typeof window != 'undefined' ) this.init(el) // 非ssr环境下初始化, 否则由用户初始化
      CustomDetailDialog.instance = this
    }
    return CustomDetailDialog.instance // 单例模式,避免弹窗重复创建和销毁
  }
  async init(el = null) {
    if(this.language) return
    const { language } = await getLanguage()
    this.language = language

    this.previewInstance = new ImagePreviewDialogCreator({
      propsData: {
        language: this.language
      }
    }).$mount()
    document.body.appendChild(this.previewInstance.$el)

    if(this.instance) return
    this.instance = new customDetailDialogCreator({
      propsData: {
        language: this.language,
        previewInstance: this.previewInstance
      }
    }).$mount()
    if(el) el.appendChild(this.instance.$el)
    else document.body.appendChild(this.instance.$el)
  }
  open(props) {
    if(!this.instance) {
      console.error('CustomDetailDialog instance is not exist')
      return
    }
    this.instance.open(props)
  }
  close() {
    this.instance.close()
  }
  addListener(options){
    Object.keys(options).forEach(name => {
      if(typeof options[name] == 'function') this.instance.$on(name, options[name])
    })
  }
  removeListener(options){
    Object.keys(options).forEach(name => {
      if(typeof options[name] == 'function') this.instance.$off(name, options[name])
    })
  }
}

export default new CustomDetailDialog()
